import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//连续点击多次路由报错解决方法
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	routes: [
		{
			path: '/',
			name: 'in',
			component: () => import('@/views/Fund.vue'),
			meta: {
				title: 'Insure',
				active: 'in'
			}
		},
		{
			path: '/insure',
			name: 'in',
			component: () => import('@/views/Insure.vue'),
			meta: {
				title: 'Insure',
				active: 'in'
			}
		},
		{
			path: '/fund',
			name: 'fund',
			component: () => import('@/views/Fund.vue'),
			meta: {
				title: 'Fund',
				active: 'in'
			}
		},
		{
			path: '/member',
			name: 'member',
			component: () => import('@/views/Member.vue'),
			meta: {
				title: 'member',
				active: 'member'
			}
		},
		{
			path: '/home',
			name: 'home',
			component: () => import('@/views/Home.vue'),
			meta: {
				title: '首页',
				active: 'home'
			}
		},
		{
			path: '/share',
			name: 'home',
			component: () => import('@/views/Share.vue'),
			meta: {
				title: '分享',
				active: 'share'
			}
		}
	]
})

export default router
