export default {
  common:['Gửi đi','Thành công'],
  member:{
    title:"Xác minh nhân viên",
    name:'Xác minh nhân viên dịch vụ',
    submit:'Tra cứu ngay',
    submit2:'Xác nhận',
    title2:'Kết quả xác minh mã số nhân viên bạn đã nhập',
    status:['Họ tên','Mã số','Cơ quan','Luôn hoan nghênh thông tin của bạn, tôi sẽ cung cấp cho bạn dịch vụ bảo hiểm toàn diện!'],
    status2:['Không tìm thấy nhân viên dịch vụ liên quan']
  },
  member_placeholder:{
    name:'Vui lòng nhập mã số nhân viên',
  },
  re:{
    title:'Đơn xin tài chính',
    title2:'Yêu cầu bồi thường chậm chuyến bay',
    status:['Đang chờ xử lý','Yêu cầu không thành công , tài khoản ngân hàng của bạn chưa kích hoạt tính năng tự động nhận tiền liên kết VNPAY. Vui lòng kích hoạt tính năng để yêu cầu bồi thường!'],
    submit:'Yêu cầu bồi thường',
    submit2:'Xác nhận',
    name:'Họ tên',
    account:'Số tài khoản',
    amount:'Số tiền bồi thường',
    air:'Số chuyến bay thay đổi',
  },
  re_placeholder:{
    name:'Vui lòng nhập họ tên của bạn',
    account:'Vui lòng nhập tài khoản của bạn',
    amount:'Vui lòng nhập số tiền bồi thường',
    air:'Vui lòng nhập số chuyến bay thay đổi',
  },
  fund:{
    title:'Đơn xin thanh toán vốn',
    status:['Đang đếm ngược tra cứu','Giây','Vui lòng kiên nhẫn chờ kết quả tra cứu','Vui lòng không đóng trang tra cứu'],
    submit:'Gửi',
    submit2:'Xác thực tự động',
    name:'Họ tên',
    description:'Giải thích đơn xin',
  },
  fund_placeholder:{
    name:'Họ tên',
    description:'Vui lòng nhập nội dung',
  }
};
