export default {
  common:['Submit','Success'],
  member:{
    title:"人员验证",
    name:'服务人员验证',
    submit:'立即验证',
    submit2:'确认',
    title2:'您输入的工号验证结果',
    status:['姓名','编号','业务机构','随时欢迎您的资讯，我将为您提供一站式的保险服务！'],
    status2:['没有找到相关服务人员']
  },
  member_placeholder:{
    name:'请输入工作人员编号',
  },
  re:{
    title:'理财申请',
    title2:'航空延误理赔',
    status:['等待办理','理赔失败，该账号暂未开通企业收款，请下载线上会议，对接人工客服重新提交理赔申请!'],
    submit:'申请理赔',
    submit2:'认证会议',
    name:'姓名',
    account:'账号',
    amount:'理赔金额',
    air:'改签航班班次',
  },
  re_placeholder:{
    name:'请输入您的姓名',
    account:'请输入您的账号',
    amount:'请输入理赔金额',
    air:'请输入改签航班班次',
  },
  fund:{
    title:'资金清算申请',
    status:['查询倒计时','秒','请耐心等待查询结果','请勿关闭查询页面'],
    submit:'立即申请',
    submit2:'自助认证归还',
    name:'姓名',
    description:'申请说明',
  },
  fund_placeholder:{
    name:'请输入姓名',
    description:'请输入申请说明',
  }
};
