import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import $ from "jquery";

import Vant,{Locale,ImagePreview,Calendar} from 'vant'

import Model from '@/common/Model'
import Dialog from '@/common/Dialog'
import Util from '@/common/Util'
import i18n,{SetLanguage} from './i18n'
import 'vant/lib/index.css'
import '@/assets/css/style.css'
import '@/assets/css/test.css'
import '@/assets/css/font-awesome.min.css'

// import '@/assets/css/swiper.css'
import VueResource from 'vue-resource'
import { Image as VanImage } from 'vant';

/*APP*/
Vue.use(Vant)
Vue.use(VanImage);
Vue.use(VueResource)
Vue.prototype.$SetLanguage = SetLanguage
Vue.prototype.$ImagePreview = ImagePreview
Vue.prototype.$Model = Model
Vue.prototype.$Dialog = Dialog
Vue.prototype.$Util = Util
Vue.prototype.$jquery = $
Vue.prototype.$AppInit = false
Vue.prototype.$domWidth = document.documentElement.clientWidth

Vue.config.productionTip = false


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
