import $Dialog from '@/common/Dialog'
import i18n from '@/i18n'
import axios from "./Axios";

const util = {
  MoneyFormat: function(val){
    return (val/10000).toLocaleString();
  },
  CurrencyIcon:function(curr){
    var icon = 'R$'
    switch (curr){
      case 'ptb':
        icon = 'R$';
      case 'USDT':
        icon = 'USDT';
        break;
    }
    return icon;
  },
  KeepDecimal: function(number,val){
    var bei = 1;
    switch(val){
        case 1:
            bei = 10;
            break;
        case 2:
            bei = 100;
            break;
        case 3:
            bei = 1000;
            break;
        case 4:
            bei = 10000;
            break;
    }
    return Math.round(number*bei)/bei;
  },
  CopyText: function (ios,app) {

    if(window.plus){
      var text = document.getElementById(ios).innerText;
      switch ( plus.os.name ) {
        case "Android":
          var Context = plus.android.importClass("android.content.Context");
          var main = plus.android.runtimeMainActivity();
          var clip = main.getSystemService(Context.CLIPBOARD_SERVICE);
          plus.android.invoke(clip,"setText",text);
        break;
        case "iOS":
          var UIPasteboard  = plus.ios.importClass("UIPasteboard");
          var generalPasteboard = UIPasteboard.generalPasteboard();
          // 设置/获取文本内容:
          generalPasteboard.setValueforPasteboardType(text, "public.utf8-plain-text");
          var value = generalPasteboard.valueForPasteboardType("public.utf8-plain-text");
        break;
      }
      $Dialog.Toast(i18n.t('dialog[3]'));
    }else{
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        window.getSelection().removeAllRanges();
        var Url2 = document.getElementById(ios);
        var range = document.createRange();
        range.selectNode(Url2);
        window.getSelection().addRange(range);
        var successful = document.execCommand('copy');
        if(successful){
          $Dialog.Toast(i18n.t('dialog[3]'));
        }else{
          $Dialog.Toast(i18n.t('dialog[4]'));
        }
        window.getSelection().removeAllRanges();
      }else{
        var Url2=document.getElementById(app);
        console.log("aee"+Url2);
        Url2.select();
        document.execCommand("Copy");
        $Dialog.Toast(i18n.t('dialog[3]'));
      }
    }
  },
	SortBy: function(arr, key, desc) {
    return arr.sort(function(a, b) {
      if (key) {
      	if(desc){
      		return b[key] - a[key];
      	}else{
      		return a[key] - b[key];
      	}
      } else {
      	if(desc){
      		return b - a;
      	}else{
        	return a - b;
      	}
      }
    });
	},
  DateFormat(fmt, date) {
    date = new Date(date);
    let ret;
    let opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "M+": (date.getMonth() + 1).toString(),     // 月
        "D+": date.getDate().toString(),            // 日
        "h+": date.getHours().toString(),           // 时
        "m+": date.getMinutes().toString(),         // 分
        "s+": date.getSeconds().toString(),         // 秒
        "S+" : date.getMilliseconds().toString()     //毫秒
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
    };
    return fmt;
  },
  AddSubDate(n) {
    var dd = new Date();
    dd.setDate(dd.getDate()+n);
    var YY = dd.getFullYear();
    var MM = (dd.getMonth()+1)<10?"0"+(dd.getMonth()+1):(dd.getMonth()+1);
    var DD = dd.getDate()<10?"0"+dd.getDate():dd.getDate();
    return YY+"-"+MM+"-"+DD;
  },
  CompressImg: function (imgType, imgSrc, maxW, callback) {
    var newImg = new Image();
    newImg.src = imgSrc;
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    newImg.onload = function () {
      // 图片原始尺寸
      var originWidth = this.width;
      var originHeight = this.height;
      // 最大尺寸限制
      var maxWidth = maxW, maxHeight = maxW;
      // 目标尺寸
      var targetWidth = originWidth, targetHeight = originHeight;
      // 图片尺寸超过限制
      if (originWidth > maxWidth || originHeight > maxHeight) {
        if (originWidth / originHeight > maxWidth / maxHeight) {
          // 更宽，按照宽度限定尺寸
          targetWidth = maxWidth;
          targetHeight = Math.round(maxWidth * (originHeight / originWidth));
        } else {
          targetHeight = maxHeight;
          targetWidth = Math.round(maxHeight * (originWidth / originHeight));
        }
      }
      // canvas对图片进行缩放
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      // 清除画布
      context.clearRect(0, 0, targetWidth, targetHeight);
      // 图片压缩
      context.drawImage(newImg, 0, 0, targetWidth, targetHeight);
      var base64 = canvas.toDataURL(imgType);
      var arr = base64.split(',');
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      //转换成file对象
      callback(new File([u8arr], '', { type: imgType }));
    };
  },
  OpenUrl(url) {
    // window.open('', '_blank').location = url
    if (window.plus) {
      plus.runtime.openURL(url)
    } else {
      window.open(url)
    }
  },
  PushLog(json) {
    axios.post('Line/pushLog',json);
  },
  callAppVerify(type,info,rkey){
    const u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    // console.log("call verify:"+(this.params["token"]));

    try{
        var params= {"type":type,"steps":info.steps,"captcha":{"lang":info.lang},"phone":rkey,"score":info.live_face_score}
        var data = JSON.stringify(params);
        console.log("startVerify:"+type+data);
        if (isAndroid) {
          window.AndroidObj.SartVerify(data);
          return 1;
        }else if (isIOS){
          window.webkit.messageHandlers.StartVerify.postMessage(data);
          console.log("call ios end:")
          return 2;
        }
      }catch (e) {
        console.log("error:readAppStorage")
      }
      return 0;
  },
  VerifyInfoHandle(model,params,config_info,other){
    this.Loading(true)
    var data = JSON.parse(params);
    if (data["error"]&&data["error"]!=0){
      this.Loading(false)
      model.$toast("(-1)verify fail, please try it again:"+data["error"]);
      return false
    }
    // if (data["type"]!=1){
    //   model.$toast("not type");
    //   return false
    // }
    var info = config_info
    if (data["score"])
      data["score"] = parseFloat(data["score"])
    // this.$toast(":"+JSON.stringify(info.steps))
    var verify = this.verifyData(data,info)
    if (!verify){
      this.Loading(false)
      // model.$toast("(-2)verify fail, please try it again,score:"+data["score"]+",need score:"+info.live_face_score)
      model.$toast("(-2)verify fail, please try it again")
      return false
    }
    var verify_data = {"a":data["phone"],"t":data["type"]}
    if (other!=null){
		//人脸识别不可用，缺少o，o是干啥的？ 20210627
		//提现的时候才有other
		//注册的时候没有other
      verify_data["o"] = other
    }
    var bb = {}
    if (data["device"])
      bb["dd"] = data["device"]
    if (data["ip"])
      bb["ii"] = data["ip"]
    verify_data["b"] = bb
    var cc = {}
    var pic_count = 3
    if (data["pic_list"]&&data["pic_list"].length>=pic_count){
      var pics = []
      for (var i=0;i<pic_count;i++){
        pics[i] = data["pic_list"][i]
      }
      cc["pi"] = pics
    }
    if (data["score"])
      cc["ss"] = data["score"]
    var key = new Date()
    verify_data["key"] = key
    verify_data["c"] = cc
    model.$Model.UserFaceData(verify_data,data=>{
      model.$toast(JSON.stringify(data));
    });
    // model.$toast("Start verify 2....")
    if (info.server_verify!=true){
      // model.$toast("verify successful")
      model.confirmSubmit(key)
      this.Loading(false)
      return true
    }

	// alert(JSON.stringify(verify_data.o))
	//如果是注册不做人脸比对
	if(verify_data.o.uu>0){
		//人脸对比接口
		model.$Model.UserVerify(verify_data,data=>{
			// console.log('UserVerify',verify_data)
		  model.$toast(JSON.stringify(data));
		  if(data.code==1){
		    model.$toast("verify successful")
		    model.confirmSubmit()
		    this.Loading(false)
		  }else{
		    model.$toast("(-3)verify fail, please try it again")
		    this.Loading(false)
		  }
		});
	}else{
		model.confirmSubmit()
		this.Loading(false)
	}
  },
  readAppStorage(){
    const u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    // console.log("call verify:"+(this.params["token"]));

    try{
      if (isAndroid) {
        window.AndroidObj.ReadStorage();
        return 1;
      }else if (isIOS){
        window.webkit.messageHandlers.ReadStorage.postMessage(null);
        console.log("call ios end:")
        return 2;
      }
    }catch (e) {
      console.log("error:readAppStorage")
    }
    return 0;
  },
  writeAppStorage(data){
    const u = navigator.userAgent;
	console.log(u)
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    // console.log("call verify:"+(this.params["token"]));
    try{
      if (isAndroid) {
        window.AndroidObj.WriteStorage(data);
        return 1;
      }else if (isIOS){
        window.webkit.messageHandlers.WriteStorage.postMessage(data);
        console.log("call ios end:")
        return 2;
      }
    }catch (e) {
        console.log("error:writeAppStorage"+data)
      }
    return 0;
  },
  verifyData(data,info){
     //live face verify:
    var steps = info.steps;
    if (steps.includes(2)){
      var score = info.live_face_score
      return (data["score"]>=score)
    }
    return true
  },
  Loading(run,tip){
    var ll_css = document.getElementById("loading_css");
    var tip_ele = document.getElementById("loading_tip");

    if (ll_css){
      if (run){
        if (tip){
          tip_ele.innerText=tip;
        }
        console.log("inline"+ll_css);
        ll_css.style.display = "inline"
        tip_ele.style.display = "inline"
      }else{
        ll_css.style.display = "none"
        tip_ele.innerText = ""
        tip_ele.style.display = "none"
      }
    }
  },
  CopyTexts: function (ios,app) {
	var uid = JSON.parse(localStorage.UserInfo).uid;
    if(window.plus){
      var text = document.getElementById(ios).innerText + 'https://dzb126.com/#/user/promotekid/'+uid;
      switch ( plus.os.name ) {
        case "Android":
          var Context = plus.android.importClass("android.content.Context");
          var main = plus.android.runtimeMainActivity();
          var clip = main.getSystemService(Context.CLIPBOARD_SERVICE);
          plus.android.invoke(clip,"setText",text);
        break;
        case "iOS":
          var UIPasteboard  = plus.ios.importClass("UIPasteboard");
          var generalPasteboard = UIPasteboard.generalPasteboard();
          // 设置/获取文本内容:
          generalPasteboard.setValueforPasteboardType(text, "public.utf8-plain-text");
          var value = generalPasteboard.valueForPasteboardType("public.utf8-plain-text");
        break;
      }
      $Dialog.Toast(i18n.t('dialog[3]'));
    }else{
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        window.getSelection().removeAllRanges();
        var Url2 = document.getElementById(ios);
		var text = Url2.innerText;
		Url2.innerText = text + 'https://dzb126.com/#/user/promotekid/'+uid;
        var range = document.createRange();
        range.selectNode(Url2);
        window.getSelection().addRange(range);
        var successful = document.execCommand('copy');
		// console.log(range)
        if(successful){
          $Dialog.Toast(i18n.t('dialog[3]'));
		  Url2.innerText = text;
        }else{
          $Dialog.Toast(i18n.t('dialog[4]'));
		  Url2.innerText = text;
        }
        window.getSelection().removeAllRanges();
      }else{
        var Url2=document.getElementById(app);
        console.log("aee"+Url2);
        Url2.select();
        document.execCommand("Copy");
        $Dialog.Toast(i18n.t('dialog[3]'));
      }
    }
  },
  CopyUrl: function (ios,app) {
	var Url2 = document.getElementById(ios);
	Url2.style.display = 'block';
	Url2.select();
	document.execCommand('copy');
	Url2.style.display = 'none';
	$Dialog.Toast(i18n.t('dialog[3]'));
  },

}
export default util
