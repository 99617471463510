import Vue from 'vue'
import {Locale} from 'vant'
import VueI18n from 'vue-i18n'
import config from '@/config'
import English from "./en";
import vn from "./vn";
import tc from "./tc";
import enUS from "vant/lib/locale/lang/en-US";
//设置默认语言
var Language = config.Language

/*初始化*/
const lang = "vn";

let locale = "vn";
switch (lang) {
    case "en":
        locale = "en-US";
        Locale.use("en-US", enUS);
        break;
    case "vn":
        locale = "vn";
        Locale.use("vn", vn);
        break;
    case "tc":
        locale = "zh-HK";
        Locale.use("zh-HK", tc);
        break;

}


Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: locale,
    messages: {
        "en-US": English,
        "vn": vn,
    }
})
export function SetLanguage(lang) {
    i18n.locale = lang;
    switch (lang) {
        case "en-US":
            localStorage["Language"] = "en";
            Locale.use("en-US", enUS);
            break;
        case "vn":
            localStorage["Language"] = "vn";
            Locale.use("vn", vn);
            break;
    }
}
export default i18n
