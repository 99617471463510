import { Dialog, Toast} from 'vant'
import i18n from '@/i18n'

const dialog = {
	Confirm(msg,event,btnText,title) {
		Dialog.confirm({
			title: title||i18n.t('dialog[0]'),
      message: msg,
      confirmButtonText: btnText||i18n.t('dialog[1]'),
      closeOnPopstate: true
    }).then(event).catch(()=>{});
	},
	Alert(msg,customClass,event) {
		Dialog.alert({
			// title: i18n.t('dialog[0]'),
			message: msg,
			confirmButtonText: 'OK',
			closeOnPopstate: true,
			center: true,
			showConfirmButton:false,
			customClass:customClass,
			closeOnClickOverlay: true
		}).then(event).catch(()=>{});
	},
	Toast(msg,position) {
		Toast({
			message: msg,
			position: position||'bottom'
		});
	},
	Loading(msg,duration,el) {
		Toast.loading({
			message: msg||i18n.t('dialog[2]'),
		  duration: duration,
		  forbidClick: true,
		  getContainer: el||'body'
		});
	},
	Close() {
		Dialog.close();
		Toast.clear();
	},
}

export default dialog
