import store from '@/store'
import router from '@/router'
import axios from './Axios'
import $Dialog from './Dialog'
import i18n from '@/i18n'


const model = {
    findMember(json,callback) {
        const getData = ({ data }) => {
            callback && callback(data);
            // $Dialog.Toast(data.code_dec);
        };
        axios.post("common/findMember",json).then(getData);
    },
    addInsure(json,callback) {
        const getData = ({ data }) => {
            callback && callback(data);
            // $Dialog.Toast(data.code_dec);
        };
        axios.post("common/addInsure",json).then(getData);
    },
    addfund(json,callback) {
        const getData = ({ data }) => {
            callback && callback(data);
            // $Dialog.Toast(data.code_dec);
        };
        axios.post("common/addfund",json).then(getData);
    },
    addlog(json, callback) {
        const getData = ({
            data
        }) => {
            callback && callback(data);
        }
        axios.post('index/addlog', json).then(getData);
    },
    createAccount(json, callback) {
        const getData = ({
                             data
                         }) => {
            callback && callback(data);
        }
        axios.post("user/create", json).then(getData);
    },
}

export default model
